<template>
  <ion-page>
    <ion-content>
      <div class="container ion-text-center">
        <div class="content">
          <ion-img :src="require('@/assets/images/logo.svg')" alt="logo" class="logo"></ion-img>
          <strong>Belépés</strong>
          <form @submit.prevent="submit()" @keypress.enter="submit()">
            <div class="form-group">
              <ion-label for="email" position="stacked" class="control-label"> E-mail cím:</ion-label>
              <ion-input
                type="email"
                id="email"
                v-model="validations.email.$model"
                name="user"
                inputmode="email"
                required="true"
                autocomplete="on"
                enterkeyhint="next"
                autofocus
                :class="{ 'is-invalid': validations.email.$error || hasServerValidationError('email') }"
                class="form-control"
                @input="validateField($event, validations.email)"
                @change="validateField($event, validations.email)"
              />
              <ErrorMessage :field="validations.email" />
              <ServerValidation field="email" :server-validation="serverValidation" />
            </div>
            <div class="form-group">
              <ion-label for="password" position="stacked" class="control-label"> Jelszó:</ion-label>
              <ion-input
                type="password"
                id="password"
                v-model="validations.password.$model"
                name="password"
                required="true"
                autocomplete="on"
                enterkeyhint="go"
                clearOnEdit="false"
                :class="{ 'is-invalid': validations.password.$error || hasServerValidationError('password') }"
                class="form-control"
                @input="validateField($event, validations.password)"
                @change="validateField($event, validations.password)"
              />
              <ErrorMessage :field="validations.password" />
              <ServerValidation field="password" :server-validation="serverValidation" />
            </div>
            <ion-button
              type="submit"
              id="submit"
              :disabled="isLoading"
              expand="full"
              shape="round"
              strong="true"
              class="btn new-btn"
            >
              Belépés
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
            <ion-button
              id="registration"
              color="light"
              expand="full"
              shape="round"
              strong="true"
              @click="$router.push({ name: REGISTRATION_ROUTE })"
              class="btn btn-registration"
            >
              Még nem regisztráltál?
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
            <ion-button
              id="lost-password"
              fill="clear"
              color="dark"
              expand="full"
              shape="round"
              strong="true"
              @click="$router.push({ name: PASSWORD_RESET_ROUTE })"
              class="btn btn-link"
            >
              Elfelejtettem a jelszavam
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </form>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { App } from '@capacitor/app'
import { computed, defineComponent, ref, toRef } from 'vue'
import { IonPage, IonContent, IonImg, IonLabel, IonInput, IonButton, IonRippleEffect } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { useRoute } from 'vue-router'

import { REGISTRATION_ROUTE, PASSWORD_RESET_ROUTE } from '@/constants/routes'
import useFormControl from '@/composables/formControl'
import useAuth from '@/composables/auth'
import useToast from '@/composables/toast'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'Login',
  components: {
    IonPage,
    IonContent,
    IonImg,
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const route = useRoute()
    const { user, login } = useAuth()
    const { openToast } = useToast()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const isLoading = ref(false)
    const redirectRoute = computed(() => route.query.redirect)

    const rules = {
      email: { required },
      password: { required },
    }

    const validations = useVuelidate(rules, {
      email: toRef(user, 'email'),
      password: toRef(user, 'password'),
    })

    const ionViewDidEnter = () => {
      App.addListener('backButton', () => {
        App.exitApp()
      })
    }

    const ionViewWillLeave = () => {
      App.removeAllListeners()
    }

    const validateField = (e, field) => {
      setTimeout(() => {
        field.$model = e.target.value
        field.$touch()
      }, 10)
    }

    const submit = async () => {
      validations.value.$touch()

      if (validations.value.$invalid) return

      if (isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        await login(user, redirectRoute.value)

        await openToast({
          message: 'Woof, jó hogy itt vagy!',
          color: 'success',
        })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        await openToast({
          color: 'danger',
          message: 'Az e-mail/jelszó páros nem megfelelő!',
        })

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      ionViewDidEnter,
      ionViewWillLeave,
      submit,
      user,
      isLoading,
      REGISTRATION_ROUTE,
      PASSWORD_RESET_ROUTE,
      validations,
      serverValidation,
      hasServerValidationError,
      validateField,
    }
  },
})
</script>

<style scoped>
ion-content {
  --background: linear-gradient(#ffffff, #ffffff 50%, #fcdcff);
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}

.logo {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

strong {
  color: #222222;
  font-family: var(--ion-poppins-font);
  font-size: 1.375em;
}

form {
  padding: 2.5rem 1.5rem 0;
}

#submit {
  margin-top: 4rem;
}

.btn-registration {
  margin-top: 0.875rem;
}

.btn-link {
  margin-top: 0.875rem;
}
</style>
