<template>
  <ion-page>
    <ion-content class="gradient-bg" fullscreen>
      <div class="container">
        <div class="content">
          <ion-img :src="require('@/assets/images/icons/forgot-password.svg')"></ion-img>

          <template v-if="!success">
            <h1 class="title ion-text-center">Elfelejtett jelszó</h1>

            <form @submit.prevent="submit" novalidate>
              <div class="form-group">
                <ion-label for="email" class="control-label"> E-mail cím: </ion-label>
                <ion-input
                  type="email"
                  id="email"
                  v-model="validations.email.$model"
                  name="email"
                  inputmode="email"
                  required
                  autocomplete="email"
                  enterkeyhint="next"
                  placeholder="mintajanos@gmail.com"
                  :class="{ 'is-invalid': validations.email.$error || hasServerValidationError('email') }"
                  class="form-control"
                  autofocus
                >
                </ion-input>
                <ErrorMessage :field="validations.email" />
                <ServerValidation field="email" :server-validation="serverValidation" />
              </div>
              <ion-button
                type="submit"
                id="submit"
                expand="full"
                shape="round"
                :disabled="isLoading"
                strong="true"
                class="btn new-btn"
              >
                Küldés
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
              <ion-button
                @click="$router.push({ name: LOGIN_ROUTE })"
                fill="clear"
                expand="full"
                shape="round"
                strong="true"
                class="btn btn-link"
              >
                Mégse
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
            </form>
          </template>

          <template v-else>
            <h1 class="top">Elküldtük az emailt!</h1>

            <p>
              Elküldtük az email címedre a jelszavad helyreállításához szükséges linket. Ezt az oldalt bezárhatod és
              kattints az emailben található gombra!
            </p>

            <lottie-animation
              ref="anim"
              :loop="true"
              :autoPlay="true"
              :animationData="require('@/assets/images/lottie/emailsent.json')"
            />

            <ion-button
              @click="$router.push({ name: LOGIN_ROUTE })"
              expand="full"
              shape="round"
              strong="true"
              class="btn btn-link btn-ok"
            >
              Rendben
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </template>
        </div>

        <ion-footer>
          <ion-img :src="require('@/assets/images/logo.png')" alt="logo" class="logo"></ion-img>
        </ion-footer>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { IonPage, IonContent, IonLabel, IonInput, IonButton, IonRippleEffect, IonImg, IonFooter } from '@ionic/vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import httpClient from '@/core/api/api.service'
import { PASSWORD_RESET_ROUTE, LOGIN_ROUTE } from '@/constants/routes'

import useToast from '@/composables/toast'
import useFormControl from '@/composables/formControl'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'PasswordReset',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    IonImg,
    IonFooter,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const { openToast } = useToast()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const email = ref('')
    const isLoading = ref(false)
    const success = ref(false)

    const rules = {
      email: { required },
    }

    const validations = useVuelidate(rules, {
      email,
    })

    async function submit() {
      validations.value.$touch()

      if (validations.value.$invalid || isLoading.value) return

      try {
        serverValidation.value = {}
        isLoading.value = true

        await httpClient.save('users/password-reset', {
          email: email.value,
          reset_url: `${process.env.VUE_APP_URL}/${PASSWORD_RESET_ROUTE}`,
        })

        success.value = true
        email.value = ''
        openToast({ message: 'A jelszó emlékeztetőt elküldtük a megadott e-mail címre.' })

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    }

    return {
      submit,
      success,
      isLoading,
      validations,
      serverValidation,
      hasServerValidationError,
      LOGIN_ROUTE,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 35px;
}

.new-btn {
  --background: #e6224a;
  --box-shadow: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content {
  padding: 70px 20px 0;
  width: 100%;
}

ion-img {
  max-width: 60px;
  margin: 0 auto;
}

ion-footer {
  padding-bottom: 30px;
  ion-img {
    min-width: 100px;
  }
}

form {
  ion-button {
    margin-top: 20px;
  }
}

h1.top {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: -0.3px;
  line-height: 19.5px;
}

p {
  margin: 0 30px;
  text-align: center;
  color: #222222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 30px 0;
}

.lottie-animation {
  max-width: 201px;
  margin: 0 auto;
}

.btn-ok {
  height: 50px;
  --background: #e6224a;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.64px;
  max-width: 238px;
  margin: 34px auto 0 auto;
}
</style>
