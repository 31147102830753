<template>
  <ion-page>
    <ion-content class="gradient-bg" fullscreen>
      <div class="container">
        <div class="content">
          <ion-img :src="require('@/assets/images/icons/password.svg')" v-if="!success" />
          <lottie-animation
            v-else
            ref="anim"
            :loop="true"
            :autoPlay="true"
            :animationData="require('@/assets/images/lottie/password-success.json')"
          />

          <template v-if="!success">
            <h1 class="title ion-text-center">Jelszó beállítása</h1>

            <form @submit.prevent="submit" novalidate>
              <div class="form-group">
                <ion-label for="password" class="control-label"> Jelszó: </ion-label>
                <ion-input
                  type="password"
                  id="password"
                  v-model="validations.password.$model"
                  name="password"
                  required
                  :class="{ 'is-invalid': validations.password.$error || hasServerValidationError('password') }"
                  class="form-control"
                >
                </ion-input>
                <ErrorMessage :field="validations.password" />
                <ServerValidation field="password" :server-validation="serverValidation" />
              </div>
              <div class="form-group">
                <ion-label for="password-confirm" class="control-label"> Jelszó mégegyszer: </ion-label>
                <ion-input
                  type="password"
                  id="password-confirm"
                  v-model="validations.passwordConfirm.$model"
                  name="password-confirm"
                  required
                  :class="{
                    'is-invalid':
                      validations.passwordConfirm.$error || hasServerValidationError('password_confirmation'),
                  }"
                  class="form-control"
                >
                </ion-input>
                <ErrorMessage :field="validations.passwordConfirm" />
                <ServerValidation field="password_confirmation" :server-validation="serverValidation" />
              </div>
              <ion-button
                type="submit"
                id="submit"
                expand="full"
                shape="round"
                :disabled="isLoading"
                strong="true"
                class="btn"
              >
                Mentés
                <ion-ripple-effect></ion-ripple-effect>
              </ion-button>
            </form>
          </template>

          <template v-else>
            <h1 class="top">A jelszavad elmentettük!</h1>

            <p>
              Sikeresen megváltoztattad a jelszavad! Kattints a lenti Bejelentkezés gombra és használd az email címed és
              az új jelszavad a belépéshez.
            </p>

            <ion-button
              @click="$router.push({ name: LOGIN_ROUTE })"
              expand="full"
              shape="round"
              strong="true"
              class="btn btn-link btn-ok"
            >
              Bejelentkezem
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </template>
        </div>

        <ion-footer>
          <ion-img :src="require('@/assets/images/logo.png')" alt="logo" class="logo"></ion-img>
        </ion-footer>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import * as sha512 from 'js-sha512'
import { computed, defineComponent, reactive, toRefs, onMounted, toRef, ref } from 'vue'
import { IonPage, IonContent, IonLabel, IonInput, IonButton, IonRippleEffect, IonImg, IonFooter } from '@ionic/vue'
import { useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { LOGIN_ROUTE, REGISTRATION_ACTIVATION_ROUTE } from '@/constants/routes'

import httpClient from '@/core/api/api.service'
import useToast from '@/composables/toast'
import useNavigation from '@/composables/navigation'
import useAuth from '@/composables/auth'
import useFormControl from '@/composables/formControl'

import ErrorMessage from '@/components/Global/ErrorMessage'
import ServerValidation from '@/components/Global/ServerValidation'

export default defineComponent({
  name: 'PasswordResetNew',
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonInput,
    IonButton,
    IonRippleEffect,
    IonImg,
    IonFooter,
    ErrorMessage,
    ServerValidation,
  },
  setup() {
    const route = useRoute()
    const { openToast } = useToast()
    const { navigateTo } = useNavigation()
    const { isAuthenticated, invalidateAuthSession, login } = useAuth()
    const { serverValidation, hasServerValidationError } = useFormControl()

    const success = ref(false)

    const state = reactive({
      user: null,
      password: '',
      passwordConfirm: '',
      isLoading: false,
    })

    const rules = {
      password: { required },
      passwordConfirm: {
        required,
        password_mismatch: val => val === state.password,
      },
    }

    const validations = useVuelidate(rules, {
      password: toRef(state, 'password'),
      passwordConfirm: toRef(state, 'passwordConfirm'),
    })

    const token = computed(() => route.params.hash)

    function handleInvalidTokenError() {
      openToast({
        color: 'danger',
        message: 'A jelszó beállítási azonosító érvénytelen. Kérjük, használd a legfrissebb levélben található linket!',
      })
      throw new Error('Invalid token')
    }

    async function fetchUser() {
      try {
        const { data } = await httpClient.get('users/password', token.value)
        state.user = data.data
        return Promise.resolve()
      } catch (error) {
        handleInvalidTokenError()
      }
    }

    function resetForm() {
      state.password = ''
      state.passwordConfirm = ''
    }

    async function submit() {
      validations.value.$touch()

      if (validations.value.$invalid || state.isLoading) return

      if (!state.user) {
        handleInvalidTokenError()
      }

      try {
        serverValidation.value = {}
        state.isLoading = true

        await httpClient.save(`users/${state.user.id}/password-change`, {
          email: state.user.email,
          token: token.value,
          password: sha512(state.password),
          password_confirmation: sha512(state.passwordConfirm),
        })

        openToast({ message: 'A jelszavadat sikeresen beállítottuk' })

        if (isAuthenticated.value && route.name === REGISTRATION_ACTIVATION_ROUTE) {
          await invalidateAuthSession()
          await login({ email: state.user.email, password: state.password })
        }

        success.value = true

        resetForm()

        return Promise.resolve()
      } catch (error) {
        if (error.errStatus === 422 || error.errStatus === 404 || error.errStatus === 400) {
          serverValidation.value = Object.assign({}, error.errData.error)
        }

        return Promise.reject(error)
      } finally {
        state.isLoading = false
      }
    }

    onMounted(() => fetchUser())

    return {
      submit,
      success,
      ...toRefs(state),
      validations,
      serverValidation,
      hasServerValidationError,
      LOGIN_ROUTE,
    }
  },
})
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 35px;
}

ion-button {
  --background: #e6224a;
  --box-shadow: none;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content {
  padding: 70px 20px 0;
  width: 100%;
}

ion-img {
  max-width: 60px;
  margin: 0 auto;
}

ion-footer {
  padding-bottom: 30px;
  ion-img {
    min-width: 100px;
  }
}

form {
  ion-button {
    margin-top: 20px;
  }
}

.lottie-animation {
  max-width: 200px;
  margin: 0 auto;
}

h1.top {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px !important;
  text-align: center;
  letter-spacing: -0.3px;
  line-height: 19.5px;
  margin-top: 26px;
}

p {
  margin: 0 30px;
  text-align: center;
  color: #222222;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 30px 0;
}

.btn-ok {
  height: 50px;
  --background: #e6224a;
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.64px;
  max-width: 238px;
  margin: 104px auto 0 auto;
}
</style>
